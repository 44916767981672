<template>

<div>

<section v-html="privacyContent"></section>
</div>
   <!-- <div style="background-color:#ffffffd9">



    <section class="newsletter-wrapper footer-inner-wrap d-flex position-relative"><div class="container mt-3 text-center"><h2 class="title text-uppercase">Privacy Policy</h2> <div class="row"><div class="col-sm-12"><div class="text-center"><p>We feel a great responsibility in handling with personal information</p></div></div></div></div></section>
    <section class="footr-content-wrap mt-4"><div><div class="container"><p>Portal respects your privacy and recognizes the need to protect the personally identifiable information (any information by which you can be identified, such as name, address, and telephone number) you share with us. We would like to assure you that we follow appropriate standards when it comes to protecting your privacy on our web sites.</p>

<p>In general, you can visit Portal.com without telling us who you are or revealing any personal information about yourself. We track the Internet address of the domains from which people visit us and analyze this data for trends and statistics, but the individual user remains anonymous. Some of our web pages use "cookies" so that we can better serve you with customized information when you return to our site. Cookies are identifiers which a web site can send to your browser to keep on your computer to facilitate your next visit to our site. You can set your browser to notify you when you are sent a cookie, giving you the option to decide whether or not to accept it. The information we collect and analyze is used to improve our service to you.</p>

<p>There are times when we may collect personal information from you such as name, physical address or telephone number. It is our intent to inform you before we do that and to tell you what we intend to do with the information. Generally, you will have the option not to provide the information, and in the future you will be able to "opt out" of certain uses of the information. If you choose not to provide the information we request, you can still visit the Portal.com, but you may be unable to access certain options, offers and services.Portal will not sell, trade or disclose to third parties any information derived from the registration for, or use of, any online service (including names and addresses) without the consent of the user or customer (except as required by subpoena, search warrant, or other legal process or in the case of imminent physical harm to the user or others). Portal will allow suppliers to access the information for purposes of confirming your registration and providing you with benefits you are entitled to.</p>

<p>We will take appropriate steps to protect the information you share with us. We have implemented technology and security features and strict policy guidelines to safeguard the privacy of your personally identifiable information from unauthorized access and improper use or disclosure. Portal will continue to enhance our security procedures as new technology becomes available. If our privacy policy changes in the future, it will be posted here and a new effective date will be shown. You should access our privacy policy regularly to ensure you understand our current policies. Please reference the privacy policy in your subject line. Portal will attempt to respond to all reasonable concerns or inquiries within five business days of receipt. When you register with Portal.com, we or any of our partners/affiliate/group companies may contact you from time to time to provide the offers/information of such products/services that we believe may benefit you.</p>

<p>Thank you for using Portal!</p>
</div></div> 
</section>
   </div>  -->
</template>



<script >

import axios from "axios";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
    data() {
        return {

            privacyContent:"",
            getPrivacyUrl: "",
            privacyType: "",
            getLangname:"",
        }
    },

    methods: {

        getConfig() {
            // let getConfigData = this.$store.state.data

            // let portalLocal = localStorage.getItem("portalData")
            // let getConfigData = JSON.parse(portalLocal)
            const getConfigData = getConfigDataFromLocalStorage();
            if (getConfigData) {
                 let locale = this.getLangname;
                let getsiteContentData = getConfigData.payload.portal_configuration.site_content_data[locale]
                this.getPrivacyUrl = getsiteContentData.privacypolicy.url
                this.privacyType = getsiteContentData.privacypolicy.type
                // console.log(getConfigData, 'head...about..1..')
                // console.log(this.getPrivacyUrl, 'head...about..2..')

                this.getConfigFunc();
            }
        },

        getConfigFunc() {
            axios.get(this.getPrivacyUrl, {
                headers: {
                    "Content-Type": this.privacyType,
                },
            })
                .then((response) => {

                    this.privacyContent = response.data
                    // console.log(response.data, "getPrivacyUrlrgetPrivacyUrlrgetPrivacyUrlrab.....")

                })

                .catch((error) => {
                    console.log(error, 'erroroor.....')
                })
        },
        updateContent() {
            this.getLangname = this.$i18n.locale;
            console.log(this.$i18n.locale, 'getcontententUrl...1')
            this.getConfig();
        }
    },
    watch:{
        "$i18n.locale": {
			handler: function () {
				this.updateContent();
				this.getConfig();
				this.getConfigFunc();
			},
			deep: true,
		},
    },

    created() {
            this.updateContent();
            // this.getConfig();
            this.getConfigFunc();
    }
}

</script>
<style scoped>
@media screen and (max-width:998px) {
  :deep(.footr-content-wrap p){
    text-align:justify;
  }
}
</style>