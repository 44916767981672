<template>
   <div>
    <pre style="border: none;">
        {{this.robotsData}}
    </pre>
   </div>
</template>

<script>
import {  getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
import axios from "axios";
export default {
  data() {
    return {
        portalTitle:"", getEndpoint: "",
        robotsData:"",
        apiendpoind:""
    }},
    methods:{
        getConfig() {
      const getConfigData = getConfigDataFromLocalStorage();

      if (getConfigData) {
        this.portalTitle= getConfigData.payload.portal_configuration.portal_url
        this.apiendpoind=getConfigData.payload.portal_configuration.API_endpoints.getportalfiledata
              console.log(getConfigData,this.portalTitle,this.apiendpoind,'this.portalTitle')
      let obj={
    "request_type": "GetPortalFileData",
    "payload": {
        "portal_domain":this.portalTitle, 
        "file_name": "robots.txt"
    }
}
console.log(obj,'000')
        axios.post(this.apiendpoind, obj , {
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          if (response) {
            this.robotsData=response.data.data
            console.log(response,this.robotsData,'response333')
          }      
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
         
        });

      }
    },
    },
    created(){
this.getConfig()
    }

}

</script>