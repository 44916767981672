<template>
    <div class="row d-flex flex-column align-center py-5"  v-if="loginbox">
        <h4 class="text-center fw-bold mb-4 d-none">LOGIN</h4>
        <div class="col-lg-6 d-flex flex-column align-center" style="position: relative; z-index: 0;">
            <v-card style=" width:80%; border-radius:7px;box-shadow: 0px 0px 8px 0px black; " outlined
                v-click-outside="onClickOutside">
                <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-md-12 main-page">
                        <div class="bg-light main-page mx-auto p-3" style="background-color:#f8b2b2; width: 100%; height: auto;">
                            <!-- <div class="col-12 pe-1 pt-1 d-flex justify-end" @click="closeLoginBox()">
                                <v-icon style="color:navy; cursor:pointer" size="small">mdi-close-circle</v-icon>
                            </div> -->
                            <div class="d-flex align-center justify-content-between w-100">
                                <div class="d-flex flex-row align-center">
                                    <div class="m-2 ms-0 rounded-circle lockIcon d-flex align-center justify-center">
                                        <i class="bi bi-unlock fs-4 p-4"></i>
                                    </div>
                                    <div>
                                        <h4 class="m-0">{{ $t("loginContents.signIn") }}</h4>
                                        <p class="m-0 f-size-12">{{ $t("loginContents.subHaed") }}</p>
                                    </div>
                                </div>
                                <div class="d-flex align-center justify-end pe-2" @click="redirectHome()" style="cursor:pointer;">
                                    <v-icon size="22">mdi-close</v-icon>
                                </div>
                            </div>
                            <!-- <div>
                                <button @click="login">Login Using Google</button>
                            </div>
                            <div>
                                <button @click="loginWithMicrosoft">Login with Microsoft</button>
                            </div>
                            <div>
                                <button @click="loginWithApple">Login with Apple</button>
                            </div>
                             <div>
                                <button @click="loginWithFacebook">Login with Facebook</button>
                            </div> -->

                            <v-form ref="form" class="py-3 w-100" v-if="loginshow" v-model="valid" @submit.prevent="loginsubmitForm">

                                <v-text-field v-model="loginuser.userEmail" :rules="eMail" :label="this.$t('loginContents.labels.Email')"
                                    variant="outlined" placeholder="Enter your Email"
                                    style="font-size: 15px !important"></v-text-field>
                                <!-- <span style="color: red;">invalid Username</span><br> -->

                                <v-text-field v-model="loginuser.userPass" :rules="uPass" :label="this.$t('loginContents.labels.password')"
                                    placeholder="Enter your password" variant="outlined" required
                                    :append-inner-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                                    style="font-size: 15px !important"></v-text-field>
                                <!-- <span style="color: red;">invalid password</span> -->

                                <div class="row">
                                    <div class="col-6 pe-0">
                                        <v-checkbox v-model="terms" :label="this.$t('loginContents.labels.rememberMe')"></v-checkbox>
                                    </div>
                                    <div class="col-6">
                                        <span style="position: relative;top: 15px;">
                                            <p class="forgotStyle m-0" @click="this.loginshow = false, this.forgotshow = true">
                                                {{ $t('loginContents.buttonsLink.forgotPassword') }}</p>
                                        </span>
                                    </div>
                                </div>


                                <div>
                                    <p class="text-danger" v-if="errorMsg">{{ erMsg }}</p>
                                </div>


                                <div class="d-flex mb-3">
                                    <div class="col-9 d-flex justify-end">
                                        <v-btn style="background-color:#26225e; color:white" width="60%"
                                            :disabled="btnDisabled" type="submit">{{ $t('loginContents.buttonsLink.login') }}</v-btn>
                                    </div>
                                    <div class="col-3 ps-1 d-flex justify-center">
                                        <v-progress-circular v-if="loadLogin" :width="3" style="color:navy"
                                            indeterminate></v-progress-circular>
                                    </div>
                                </div>
                                <!-- <v-divider></v-divider> -->

                                <p class="text-center" style="font-weight: 500;font-size: 15px;">{{ $t('loginContents.labels.createAnaccount') }}
                                    <span @click="signUp" style="cursor: pointer;"> &nbsp;
                                        <!-- <b style="color:navy">Sign Up</b> -->
                                        <a href="/register" style="color:navy;text-decoration:none;font-weight:600;">{{ $t('loginContents.buttonsLink.register') }}</a></span>
                                </p>
                            </v-form>

                            <v-form ref="form" v-if="forgotshow" v-model="valid" @submit.prevent="submitResetForm"
                                style="width: 90%;">

                                <h5 class="mt-2 text-center" v-if="forgotshow">{{ $t('loginContents.labels.resetPassword') }}</h5>

                                <v-text-field v-model="resetDetail.resetMail" :rules="eMail" label="Email"
                                    variant="outlined" placeholder="Enter your email"
                                    style="height:55px;font-size: 15px !important"></v-text-field>
                                <!-- <span style="color: red;">invalid Username</span><br> -->

                                <div class="row justify-center">

                                    <div class="col-10 my-3 text-center">
                                        <span style="font-weight:500;font-size:13px;letter-spacing: 1px;">
                                            {{ $t('loginContents.labels.rememberPass') }} 
                                            <span @click="this.loginshow = true, this.forgotshow = false"
                                                style="color:#0D6EFD; text-decoration:underline; cursor:pointer">{{ $t('loginContents.buttonsLink.login') }}</span>
                                        </span>
                                    </div>
                                </div>


                                <div>
                                    <p class="text-danger" v-if="errforget">{{ $t('loginContents.errorNotify.text3') }}.</p>
                                </div>



                                <div class="d-flex mb-3">
                                    <div class="col-9 d-flex justify-end">
                                        <v-btn style="background-color:#26225e; color:white" width="60%"
                                            :disabled="btnDisabled" type="submit">{{ $t('loginContents.buttonsLink.submit') }}</v-btn>
                                    </div>
                                    <div class="col-3 ps-1 d-flex justify-end">
                                        <v-progress-circular v-if="loadforget" :width="3" style="color:navy"
                                            indeterminate></v-progress-circular>
                                    </div>
                                </div>
                            </v-form>



                            <div v-if="emailsentMsg">
                                <div class="d-flex justify-center align-center p-3">
                                    <p>You will receive a link to reset the password, if your email is already registered.
                                        Please follow the instructions in the email to reset your password.</p>
                                </div>

                                <div class="d-flex justify-center align-center p-3">
                                    <span @click="this.loginshow = true, this.forgotshow = false, this.emailsentMsg = false"
                                        style="font-weight:500;font-size:13px;letter-spacing: 1px;">
                                        {{ $t('loginContents.labels.rememberPass') }} <span
                                            style="color:#0D6EFD; text-decoration:underline; cursor:pointer">{{ $t('loginContents.buttonsLink.login') }}</span>
                                    </span>
                                </div>
                            </div>
                            <div v-if="loginsuccess">
                                <v-dialog v-model="dialog" max-width="500">
                                <v-card>
                                    <v-card-text class="text-success">
                                        Login Successfully....
                                    </v-card-text>
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text="Close" to="/" @click="closeDialog()" style="background-color:#26225e; color:white !important;"></v-btn>
                                    <!-- <v-btn text="Close" @click="closeDialog()" style="background-color:#26225e; color:white"></v-btn> -->
                                    </v-card-actions>
                                </v-card>
                                </v-dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card>
        </div>
    </div>

     <div class="row d-flex flex-column align-center py-5"  v-if="errorMsg1">
        <div class="col-lg-6 d-flex flex-column align-center" style="position: relative; z-index: 0;">
            <v-card style=" width:80%; border-radius:7px;box-shadow: 0px 0px 8px 0px black; " outlined
                v-click-outside="onClickOutside">
                 <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-md-12 main-page">
                        <div class="bg-light main-page mx-auto p-3" style="background-color:#f8b2b2; width: 100%; height: auto;">
                            <div>{{errorMsgData}}</div>
                        </div>
                    </div>
                 </div>
            </v-card>
        </div>
     </div>


    <div class="text-center" v-if="dataloader">
        <dataloader></dataloader>
    </div>

    <!-- <div v-if="registerCom">
        <registerCom />
    </div> -->
</template>


<script>
// import registerCom from '@/components/registerCom.vue';
import axios from 'axios'
// import dataloader from "@/components/dataLoder.vue";
import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';
// import router from '@/router/route';
import { googleAuthCodeLogin } from "vue3-google-login";
import { microsoftAuth } from "@/plugins/microsoftAuth";
// import FacebookLogin from "vue3-facebook-login";
// import VueAppleSignin from 'vue-apple-signin';

export default {
    components: {
        // registerCom
            // dataloader,
            // FacebookLogin,
            // VueAppleSignin
    },
    data() {
        return {
            dataloader: true,
            loginbox: true,
            registerCom: false,
            terms: false,
            visible: false,
            loadLogin: false,
            btnDisabled: false,
            errorMsg: false,

            loginshow: true,
            forgotshow: false,
            emailsentMsg: false,
            resetshow: false,
            loadforget: false,
            errforget: false,
            userName: "",
            userEmail: "",

            customKey: "qt3cU5nWjV2OPeQBEYkwD4adr8oy9I",
            customMail: "",



            loginuser: {
                userEmail: "",
                userPass: "",
                confirmPass: "",
            },

            resetDetail: {
                resetMail: "",
            },

            eMail: [
                v => !!v || 'Please enter your email',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            uPass: [
                v => !!v || 'Please enter your Password'
            ],

            loginReq: {
                "request_type": "user_login",
                "email": "",
                "password": "",
                "portal_id": "",
                "token": "Tc7RQaX79Hrx5CFK",
                "portal_domain": "",
                "logged_in":false,
                "logged_email":""
            },

            forgetDetails: {
                "request_type": "forgot_password",
                "email": "",
                "user_id": "",
                "portal_id": "",
                "portal_domain": "",
                "logged_in":false,
                "logged_email":""
            },

            loginUrl: "",
            forgotPassword: "",
            logged_email:"",
            logged_in:false,



            // Register Code ----->

            valid: false,

            registerbox: false,
            loginSection: true,
            // terms: false,
            // visible: false,

            regForm: true,
            regMsg: false,
            registerMsg: "",
            loadRegister: false,
            loginsuccess:false,
            dialog:false,
            erMsg:"",
            // btnDisabled: false,

            personal: {
                username: "",
                email: "",
                password: "",
                rePassword: ""
            },
            


            // userName: [
            //     v => !!v || 'Please enter your Username',
            //     v => (v.length >= 3) || 'Name must be greater than 3 ',
            // ],

            // eMail: [
            //     v => !!v || 'E-mail is required',
            //     v => /.+@.+/.test(v) || 'E-mail must be valid',
            // ],

            passWord: [
                v => !!v || 'Please enter your Password',
                // v => (v && v.length >= 8) || 'Password must be at least 8 characters',
                // v => (v && v.length <= 20) || 'Password must be less than 20 characters',
                // v => (v && /[a-z]/.test(v)) || 'Password must contain at least one lowercase letter',
                // v => (v && /[!@#$%^&*]/.test(v)) || 'Password must contain at least one special character'
            ],
            repassWord: [
                v => !!v || 'Please enter your Re-password',
                v => v === this.personal.password || 'Passwords do not match'
            ],

            // strResult: "0123456789",

            // user_reqId:"",


            registerReq: {
                "request_type": "user_register",
                "user_name": "",
                "email": "",
                "password": "",
                "portal_id": "",
                "portal_group_id": "",
                "user_id": "",
                "token": "Tc7RQaX79Hrx5CFK",
                "portal_domain": ""
            },
            portal_id:"",
            registerUrl: "",
            portal_domain:"",
            emailVerificationReq:{
                "random_number":"",
                "user_id":""
            },
            random_number:"",
            user_id:"",
            errorMsgData:"",
            errorMsg1:false,
            b2cverify:"",

            // Register Code End ----->
        }
    },
    methods: {

        // signUp() {
        //     this.registerbox = true
        //     this.loginbox = false
        // },
        redirectHome(){
            this.$router.push('/')
        },

        login(){
            googleAuthCodeLogin().then((response) => {
                console.log("Handle the response", response)
            })
        },
        async loginWithMicrosoft() {
            const response = await microsoftAuth.login();
            console.log("Microsoft Login Response:", response);
        },
        async loginWithApple() {
            try {
                const response = await this.$appleSignIn();
                console.log('Apple Login Response:', response);
                // Handle the response (send to your backend, etc.)
            } catch (error) {
                console.error('Apple Login Failed:', error.message);
            }
        },
        async loginWithFacebook() {
            try {
                const response = await this.$facebookSignIn();
                console.log('Facebook Login Response:', response);
                // Use the accessToken to fetch user data or authenticate with your backend
            } catch (error) {
                console.error('Facebook Login Failed:', error.message);
            }
        },
         handleLogin(response) {
            console.log("User Successfully Logged In" , response)
        },
        handleLoginFailed() {
            console.log("User Cancelled or Abort the Login")
        },

        closeDialog(){
            // this.dialog = false;
            setInterval(() => {
                location.reload();
            }, 300);
        },
        onClickOutside() {
            // this.loginbox = false
            console.log(this.loginbox, "5555555")
        },


        loginsubmitForm() {
            if (this.valid) {
                console.log(this.loginuser, 'vvvvaaaallll')

                this.loadLogin = true
                this.btnDisabled = true

                this.loginReq.email = this.loginuser.userEmail
                this.loginReq.password = this.loginuser.userPass
                this.loginReq.portal_id = this.portal_id
                this.loginReq.portal_domain = this.portal_domain
                
                console.log(this.loginReq, 'kkkk.....')

                axios.post(this.loginUrl, this.loginReq, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log('Response:', response.data);
                        localStorage.setItem("loginuser", JSON.stringify(response.data))
                        this.errorMsg = false

                        // console.log(response.data.user,'.....Response...:');

                        // this.forgetDetails.user_id=response.data.user.user_id

                        // console.log(this.forgetDetails.user_id,'.....Response..11111.:');

                        if(response.data.login_response == "success"){
                            this.loginsuccess = true;
                            this.dialog = true;
                            this.loadforget = false;
                            this.loadLogin = false
                        }

                        // this.loginbox = true

                        localStorage.setItem('value', true)
                        // JSON.stringify(localStorage.setItem('loginDatas', this.loginuser))
                        let logindata = JSON.stringify(this.loginuser)
                        localStorage.setItem("loginDatas", logindata)

                        // setInterval(() => {
                        //     location.reload();
                        // }, 500);

                    })
                    .catch(error => {
                        console.error('Error:', error.response ? error.response.data : error.message);
                        this.errorMsg = true
                        this.erMsg = error.response.data.message;
                        console.log(this.erMsg,'erMsgerMsg')
                        // alert("Incorrect email or password...")
                        this.loadLogin = false
                        this.btnDisabled = false
                    });
            }

        },



        submitResetForm() {
            // this.loginbox = false;

            if (this.valid) {
                // this.emailsentMsg = true
                // this.forgotshow = false
                this.loadforget = true

                this.forgetDetails.email = this.resetDetail.resetMail
                this.forgetDetails.user_id = this.resetDetail.resetMail
                this.forgetDetails.portal_id = this.portal_id
                this.forgetDetails.portal_domain = this.portal_domain

                console.log(this.forgetDetails, 'reqqqqqqqqqq')

                axios.post(this.forgotPassword, this.forgetDetails, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                    .then(response => {
                        console.log(response, 'rrrreeeeeessssssss.....')
                        this.loadforget = false
                        if (response.data.forgot_password_response == 'success') {
                            this.forgotshow = false
                            this.emailsentMsg = true
                        }

                        else if (response.data.forgot_password_response == 'fail') {
                            this.forgotshow = true
                            this.emailsentMsg = false
                            this.loadforget = false
                            this.errforget = true
                        }
                    })
                    .catch(errors => {
                        console.log(errors, 'eeerrrrooorrr')
                        this.forgotshow = true
                    })

            }
        },

        closeLoginBox() {
            this.loginbox = false;
            this.$emit('close'); // Emit the 'close' event to the parent component
            console.log(this.loginbox, "1111111")
        },

        getConfig() {
            // let portalLocal = localStorage.getItem("portalData")
            // let getConfigData1 = JSON.parse(portalLocal)
            const getConfigData1 = getConfigDataFromLocalStorage();
            if (getConfigData1) {
                this.loginUrl = getConfigData1.payload.portal_configuration.API_endpoints.b2clogin
                this.registerUrl = getConfigData1.payload.portal_configuration.API_endpoints.b2cregister
                this.forgotPassword = getConfigData1.payload.portal_configuration.API_endpoints.forgot_password
                this.portal_id = getConfigData1.payload.portal_configuration.portal_id
                console.log(this.loginUrl, 'head...reg..1..')
                this.dataloader = false
                this.portal_domain = getConfigData1.payload.portal_configuration.portal_url.replace("https://","");
                this.b2cverify = getConfigData1.payload.portal_configuration.API_endpoints.b2cuser_verify;
            }
        },




        // Regiter code

        signInAgain(){
    //   this.loginSection = true 
      this.loginbox = true
      this.registerbox = false
      console.log(this.loginSection, this.registerbox, "Workinglogin....")
    },
    // closeRegBox() {
    //         this.loginbox = false;
    //         this.$emit('close'); // Emit the 'close' event to the parent component
    //         console.log(this.loginbox, "1111111")
    //     },


    // onClickOutside() {
    //   this.registerbox = false
    // },

    // getRandomChars(length) {
    //         let result = "";
    //         for (let i = 0; i < length; i++) {
    //             const randomIndex = Math.floor(Math.random() * this.strResult.length);
    //             result += this.strResult[randomIndex];
    //         }
    //         console.log(result, "result");
    //         return result;
    //     },
    //     getRandomInt(min, max) {
    //         return Math.floor(Math.random() * (max - min + 1)) + min;
    //     },

    //     generateBookingRefId() {
    //         const randomChars1 = this.getRandomChars(1);
    //         const randomNum = this.getRandomInt(10, 99);
    //         const randomChars2 = this.getRandomChars(1);

    //         this.registerReq.user_id = randomChars1 + randomNum + randomChars2;
    //         console.log(this.registerReq.user_id, 'this.booking_refId ')
    //     },




    closeRegisterBox() {
      this.registerbox = false;
      this.$emit('close'); // Emit the 'close' event to the parent component
      console.log(this.registerbox, '00000')
    },



    // getConfig() {
    //   // let portalLocal = localStorage.getItem("portalData")
    //   // let getConfigData1 = JSON.parse(portalLocal)
    //   const getConfigData1 = getConfigDataFromLocalStorage();
    //   if (getConfigData1) {
    //     this.registerUrl = getConfigData1.payload.portal_configuration.API_endpoints.b2cregister
    //     console.log(this.registerUrl, 'head...reg..1..')
    //   }
    // },
    verifyEmail(){
        this.emailVerificationReq.random_number = this.random_number;
        this.emailVerificationReq.user_id = this.user_id;
        axios.post(this.b2cverify ,this.emailVerificationReq,{
             headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
             console.log(response)
             if(response.data.response_type == 'Success'){
                this.loginbox = true;
             }
          })

          .catch(error => {
            console.log(error, 'wejjfuoajk')

            // console.log(error.response.data.message,'wejjfuoajk....1')
            this.errorMsg1 = true;
            if(error.response.data.message){
                this.errorMsgData = error.response.data.message
            }
          });
    }

    },

    created() {

        this.getConfig();

        let routerPath = this.$route.query;
        if(routerPath && Object.keys(routerPath).length > 0){
            this.loginbox = false;
            this.random_number = routerPath.random_number;
            this.user_id = routerPath.user_id;
            // this.verifyEmail();
        }
        else{
            this.loginbox = true;
        }
    }
}
</script>



<style scoped>

.f-size-14{
    font-size: 14px;
}
.f-size-12{
    font-size: 12px;
}



.lockIcon{
    background-color: #F8A21A;
    height: 50px;
    width: 50px;
}


/* Login Page */

.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .form-btn {
  padding-left: 40px;
  color: white;
} */

.btn-box {
    background-color: transparent;
    margin: 5px;
    padding: 2px;
    box-shadow: none;
}

.btn-active {
    background-color: blueviolet;
    color: white;
}

.v-checkbox .v-input__details {
    display: none;
}

>>>.v-label--clickable {
    cursor: pointer;
    font-size: 12px;
}

>>>.v-input__details {
    display: block !important;
}

.forgotStyle {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 1px;
    color: #0D6EFD;
    text-decoration: underline;
    cursor: pointer;
    text-align: end;
}

/* Login Page End */



@media only screen and (max-width: 992px) {

    .form-btn {
        padding: 10px 0px 0px 0px !important;
        /* color: white; */
    }

    .btn-box,
    .pipe {
        color: blueviolet !important;
    }

    .btn-active {
        background-color: blueviolet;
        color: white !important;
    }
}



/* Register code */

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .form-btn {
  padding-left: 40px;
  color: white;
} */

.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
}

.btn-active {
  background-color: blueviolet;
  color: white;
}

>>>.v-input__details {
  display: block !important;
}


/* .v-checkbox .v-input__details {
  display: none;
} */

/* Login Page End */



@media only screen and (max-width: 992px) {

  .form-btn {
    padding: 10px 0px 0px 0px !important;
    /* color: white; */
  }

  .btn-box,
  .pipe {
    color: blueviolet !important;
  }

  .btn-active {
    background-color: blueviolet;
    color: white !important;
  }
}
a:visited{
    color:navy !important;
}
.text-success{
    font-size:18px;
    font-weight:600;
}
:deep(.v-text-field .v-input__details){
  padding-inline:5px !important;
}
</style>
